* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--nunito-font);
  color: var(--primary-color);
}
:root {
  --nunito-font: "Nunito Sans", sans-serif;
  --playfair-font: "Playfair Display", serif;
  --prata-font: "Prata", serif;
  --primary-color: #343434;
  --secondary-color: #faf8f5;
}
.main-wrapper {
  position: fixed;
  background-color: red;
}
#header {
  top: 0;
  width: 100vw;
  height: 75vh;
}
#images-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.overlay {
  width: 100%;
  position: absolute;
  will-change: transform, opacity;
}
.img-title {
  text-align: center;
  font-size: 10vw;
  font-weight: 900;
}
.play-btn {
  cursor: pointer;
}

.illustration{
  display: inline-block;
  z-index: 100;
  opacity: 1;
  position: absolute;
  left: 3%;
  top: 30%;
  width: 15vw;
  animation: bounce 4s infinite;
}


@-webkit-keyframes bounce {
	0%{-webkit-transform: translateY(0);}	
	50% {-webkit-transform: translateY(-30px);}
	100% {-webkit-transform: translateY(0);}
}
.img-watchme:hover {
width: 105%;}
.img-watchme {
  width: 100%;
  height: auto;
  transition: all 0.3s;
}

.img-bg {
  z-index: 1;
  bottom: 10%;
}
.img-rock {
  z-index: 2;
  bottom: 10%;
}
.img-person {
  z-index: 3;
  bottom: 10%;
}
.img-cloud {
  z-index: 4;
  bottom: 10%;
  opacity: 70%;
}
.img-rectangle {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 5;
  bottom: -10%;
  height: 50%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 3.62%,
    #ffffff 45.62%,
    #ffffff 71.4%
  );
}
.quote-container {
  text-align: center;
}
.quote-content {
  font-size: 0.9rem;
  font-weight: 300;
}
.intro-quote {
  font-family: var(--nunito-font);
  font-weight: 500;
  padding: 20px;
}
.title-wrap {
  width: 100%;
  height: 100%;
}
.quote-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.inspiration-quote {
  margin-top: 20px;
  font-size: 1.5rem;
  font-weight: 300;
}
.inspiration-quote span {
  font-style: italic;
  font-weight: 600;
}

.iconify {
  opacity: 0.4;
}
.mouse{
  opacity: 0.7;
}
#project {
  height: auto;
  width: 100vw;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
#project-container {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
}

.line {
  width: 1.3px;
  height: 100%;
  background-color: rgb(209, 209, 209);
  margin: 0 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
.project-title {
  color: var(--primary-color);
  border: none;
  background: white;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.4em;
  padding: 20px;
}
.project-title span {
  font-weight: 600;
  color: black;
  font-size: 25px;
  line-height: 34px;
  letter-spacing: 0.4em;
}
.left-column,
.right-column {
  display: flex;
  flex-direction: column;
}
.card-container {
  position: relative;
  height: 50vw;
  width: 50vw;
  margin: 60px;
}
.card-img {
  position: absolute;
  background: url(./image/thumb-1.jpg) center center/cover;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s;
  border: 0.2rem solid white;
}
.card-img-2 {
  background: url(./image/thumb-2.jpg) center center/cover;
}
.card-img-3 {
  background: url(./image/thumb-3.jpg) center center/cover;
}
.card-img-4 {
  background: url(./image/thumb-4.jpg) center center/cover;
}
.card-content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
  transition: ease 0.2s all;
  opacity: 0;
  text-align: center;
}
.card-content:hover {
  opacity: 1;
}
.card-content i {
  cursor: pointer;
  color: var(--grey);
  font-size: 1.8rem;
  margin: 0.8rem 0;
}
.card-content i:hover {
  color: rgb(233, 64, 64);
}

.card-title {
  font-weight: bold;
  font-size: 1.5rem;
}
.card-text {
  font-size: 0.7rem;
  color: rgb(233, 64, 64);
  text-align: center;
}
.card-content button {
  font-size: 0.8rem;
  border: 0.1rem rgb(233, 64, 64) solid;
  border-radius: 5px;
  padding: 5px;
}
.card-content button a {
  text-decoration: none;
}
.card-content button a:hover {
  color: white;
}
.card-content button:hover {
  background: rgb(233, 64, 64);
  color: white;
}
#skills {
  margin: 10% 0;
  padding: 10%;
  width: 100vw;
  height: auto;
  background: var(--secondary-color);
}
.skills-heading {
  height: auto;
  margin-bottom: 50px;
  text-align: center;
}
.skills-heading h3 {
  font-size: 25px;
  font-weight: 300;
  line-height: 34px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}
.skills-heading h2 {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 600;
  font-size: 10vw;
}
.skills-cards {
  height: auto;
}
.single-skill {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 5%;
  align-items: center;
  margin-bottom: 80px;
}
.skill-content {
  text-align: center;
  margin-top: 1rem;
}
.single-skill img {
  width: 35vw;
  height: auto;
}
.single-skill h3 {
  font-weight: 400;
  font-size: 4vw;
}
.single-skill h2 {
  font-family: var(--playfair-font);
  font-weight: 600;
  font-size: 7vw;
}
.single-skill p {
  font-size: 4vw;
  width: auto;
  font-family: var(--playfair-font);
  margin-top: 0.5vw;
}
#about {
  width: 100vw;
}
.about-left {
  display: none;
}
.about-left img {
  position: relative;
  width: 40vw;
  height: auto;
  left: 0;
}
.about-right {
  padding: 10%;
}

.about-right img {
  width: 80vw;
  height: auto;
}
.about-right h1 {
  font-family: var(--playfair-font);
  font-size: 10vw;
  font-weight: 500;
  line-height: 1.1;
}
.about-right p {
  font-family: var(--playfair-font);
  font-weight: 400;
  line-height: 1.7;
}
.circle {
  position: absolute;
  height: 30vw;
  width: 30vw;
  background-color: #fcce4b;
  border-radius: 50%;
  display: inline-block;
  transform: translate(-50%, 110%);
}
#text {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#text p {
  font-size: 5vw;
  font-family: var(--playfair-font);
  font-weight: 900;
}

#exp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 140vh;
  overflow: auto;
  overflow: hidden;
}

details {
  width: 75%;
  min-height: 5px;
  max-width: 700px;
  padding: 5% 2%;
  margin: 20px auto;
  position: relative;
  font-size: 22px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  box-sizing: border-box;
  transition: all 0.3s;
}

details + details {
  margin-top: 20px;
}

details[open] {
  min-height: 50px;
  background-color: #f6f7f8;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
}

details p {
  color: #96999d;
  font-weight: 300;
  padding: 1rem 0;
  font-size: 0.8rem;
}

summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  font-size: 1rem;
}

summary:focus {
  outline: none;
}

summary:focus::after {
  content: "";
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 0 5px #fcce4b;
  border-radius: 13px;
}

summary::-webkit-details-marker {
  display: none;
}

.control-icon {
  fill: rebeccapurple;
  transition: 0.3s ease;
  pointer-events: none;
}

.control-icon-close {
  display: none;
}

details[open] .control-icon-close {
  display: initial;
  transition: 0.3s ease;
}

details[open] .control-icon-expand {
  display: none;
}

.exp-textwrap {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

#contact {
  width: 100vw;
  height: 80vh;
  background: var(--secondary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10%;
}

.white-circle {
  background: white;
  width: 450px;
  height: 450px;
  border-radius: 50%;
  border: none;
}

.white-circle a {
  text-decoration: none;
  font-size: 2.5rem;
  font-weight: 900;
  transition: all 0.3s ease-in;
}
.white-circle a:hover {
  font-size: 2.7rem;
}
.yellow-circle {
  opacity: 0;
  border: none;
  position: absolute;
  height: 220px;
  width: 220px;
  background-color: #fcce4b;
  border-radius: 50%;
  display: inline-block;
  transform: translate(20%, 0%);
}
.resume-btn {
  width: 50px;
  height: auto;
  border: black solid 0.5px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.resume-btn:hover {
  box-shadow: 2px 2px 5px grey;
}
.resume-btn a {
  text-decoration: none;
  font-size: 1rem;
  writing-mode: vertical-rl;
  text-orientation: upright;
  padding: 8px;
}
.social {
  position: fixed;
  bottom:-5%;
  left: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.social-btns {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.social-btns i {
  color: rgb(160, 160, 160);
  font-size: 32px;
}

.footer-line {
  width: 1.3px;
  height: 100px;
  background-color: rgb(209, 209, 209);
}

.credit {
  position: fixed;
  bottom: 0;
  margin: 5px;
}
#footer {
  width: 100vw;
  height: 10vh;
  display: flex;
  justify-content: center;
}

.icon-github {
  margin-left: 1.2px;
  margin-bottom: 2px;
  margin-top: 15px;
}

.video-thumbnail {
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
}

.video-wrap {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(52, 52, 52, 0.438);
  transition: all 0.3s;
  opacity: 0;
  pointer-events: none;
}
.show {
  opacity: 1;
  pointer-events: all;
}

iframe {
  width: 90vw;
  height: 60vw;
  box-shadow: 5px 5px 5px rgb(78, 78, 78);
}
.close-btn {
  font-size: 2rem;
  color: white;
  position: absolute;
  top: 10%;
  right: 10%;
  cursor: pointer;
  border: solid 1px white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
}

.circle {
  z-index: 10;
  width: 25vw;
  height: 25vw;
  transform: translate(-50%, 180%);
}
.about-right p {
  padding: 20%;
}

/* 650px */
@media screen and (min-width: 40em) {
  .yellow-circle {
    opacity: 1;
  }
  .quote-wrapper {
    opacity: 1;
  }
  iframe {
    width: 80vw;
    height: 50vw;
  }
  .intro-quote {
    font-weight: 500;
  }
  .play-btn {
  left: 3%;
  top: 30%;
  width: 8vw;
}
}

/* 800px */
@media screen and (min-width: 50em) {
  .single-skill {
    flex-direction: row;
  }
  .skill-content {
    text-align: left;
  }
  .skills-heading h2 {
    font-size: 7vw;
  }
  #skills {
    padding: 15vw;
  }
  .single-skill img {
    width: 35vw;
  }
  .single-skill h3 {
    font-size: 2.5vw;
  }
  .single-skill h2 {
    font-size: 6vw;
  }
  .single-skill p {
    font-size: 1.8vw;
  }
  .line {
    display: flex;
  }
  #project-container {
    flex-direction: row;
  }
  .card-container {
    width: 30vw;
    height: 30vw;
  }
  details {
    padding: 2% 2%;
  }
  #exp {
    height: 120vh;
  }
  #about {
    display: flex;
  }
  .about-left {
    display: inline-block;
  }
  .about-right {
    padding: 10% 0 10% 10%;
  }
  .about-right h1 {
    font-size: 5vw;
    width: 90%;
  }
  .about-right img {
    width: 100%;
    right: 0;
  }
  #header {
    height: 100vh;
  }
  iframe {
    width: 70vw;
    height: 40vw;
  }
}

/* 1200px */
@media screen and (min-width: 75em) {
  .play-btn {
  left: 3%;
  top: 25%;
  width: 6vw;
  animation: bounce 4s infinite;
}
  #header {
    height: 150vh;
  }
  .single-skill {
    gap: 10%;
  }
  .skills-heading h2 {
    font-size: 5vw;
  }
  #skills {
    padding: 5vw 20vw;
  }
  .single-skill img {
    width: 20vw;
  }
  .single-skill h3 {
    font-size: 1.5vw;
  }
  .single-skill h2 {
    font-size: 3vw;
  }
  .single-skill p {
    font-size: 1.2vw;
  }
  .card-container {
    width: 25vw;
    height: 25vw;
  }
  #about {
    padding: 10%;
  }
  .about-left img {
    width: 25vw;
  }
  .about-right img {
    width: 30vw;
    margin-left: 50%;
  }
  .about-right p {
    padding: 10%;
  }
  .circle {
    width: 20vw;
    height: 20vw;
    transform: translate(-50%, 130%);
  }
  iframe {
    width: 50vw;
    height: 30vw;
  }
}
